import moment from "moment";

export default () => ({
  // ? Price Format
  price: (value = '', currency = 'Rp', maxFraction = 0) => {
    const price = value.toLocaleString('id', { maximumFractionDigits: maxFraction });
    return `${(currency ? `${currency} ` : '')}${price}` 
  },
  // ? Date Format
  date: (value, format = "D MMM YYYY") => value ? moment(value).format(format) : value,
  remainingDateDuration: (epochMs, type) => {
    // ? type value can be : years || months || weeks || days || hours || minutes || seconds
    // ? Reference : https://momentjs.com/docs/#/displaying/difference/
    if (!type && !epochMs) return console.error("Please check `epochMs & type` parameters"); 
    // const duration = moment.duration(epochMs, "milliseconds");

    // console.log("duration", duration);

    const eventDate = moment(epochMs);
    const todayDate = moment();
    return eventDate.diff(todayDate, type);

    // if (type == "days") {
    //   const daysLeft = duration.asDays();
    //   return daysLeft;
    // }
    // if (type == "hours") {
    //   const hoursLeft = duration.asDays();
    //   return hoursLeft;
    // }
    // if (type == "minutes") {
    //   const minutesLeft = duration.asDays();
    //   return minutesLeft;
    // }
  },
  epochMsToLocalTime: (value, format = "DD MMMM YYYY", locale = "en") => value ? moment.unix(value/1000).locale(locale).format(format) : value,
  todayYesterday:(value, format) => {
    moment.locale("en")
    return value ? moment(value).calendar(null, {
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      lastWeek: format,
      sameElse: format,
    }) : ""
  },
  compactPrice:(value, showCurrency = true, maximumFractionDigits = 2, currency = "IDR", locale = "id-ID") => {
    const isSafari = navigator.userAgent.includes("Safari");

    if (!value) return value;
    
    if (isSafari) return `${showCurrency ? "Rp " : ""}${new Intl.NumberFormat(locale, { notation: "compact", maximumFractionDigits, maximumSignificantDigits: 3, compactDisplay: "short" }).format(value)}`; //* Temporary for handling bug for safari browser, No spacing between "currency symbol" & "currency value" 
    else return new Intl.NumberFormat(locale, { notation: "compact", maximumFractionDigits, maximumSignificantDigits: 3, compactDisplay: "short", ...(showCurrency ? { style: "currency", currency: currency } : undefined) }).format(value);
  },
  slug:(str) => str ? str.toLowerCase().match(/[a-z0-9]+/g).join('-') : str
})